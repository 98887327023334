.paywall-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
}

.paywall-offer-view-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  margin-top: 55px;
  &-mob {
    @extend .paywall-offer-view-container;
  }
}

.paywall-offer-background-container {
  position: relative;
  &-mob {
    @extend .paywall-offer-background-container;
  }
}

.paywall-offer-before-container {
  @extend .background-container;
  background: url(../imgs/paywall-before-background.webp) no-repeat;
  background-size: contain;
  width: -webkit-fill-available;
  z-index: 1;
  &-mob {
    @extend .paywall-offer-before-container;
  }
}

.paywall-offer-after-container {
  @extend .background-container;
  background: url(../imgs/paywall-after-background.webp) no-repeat;
  background-size: contain;
  width: -webkit-fill-available;
  opacity: 0;
  transition: opacity .5s;
  z-index: 2;
  &-mob {
    @extend .paywall-offer-after-container;
  }
}

.paywall-offer-bar {
  display: flex;
  height: 0px;
  background: #F3E494;
  transition: all .5s;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  transform: rotateX(90deg);
  &-active {
    @extend .paywall-offer-bar;
    height: 40px;
    transform: rotateX(0deg);
  }
  &-mob {
    @extend .paywall-offer-bar;
    &-active {
      @extend .paywall-offer-bar-active;
    }
  }
}

.paywall-offer-text {
  @include sf-bold;
  font-weight: 800;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  &-mob {
    @extend .paywall-offer-text;
  }
}

.paywall-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgba(180,20,130,0) 25%, rgba(0,0,0,1) 43%);
  z-index: 3;
  height: 100%;
  overflow-y: auto;
  &-mob {
    @extend .paywall-body;
  }
}

.paywall-description-list {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  gap: 17px;
  align-self: center;
  &-mob {
    @extend .paywall-description-list;
  }
}

.paywall-description {
  @include sf-semibold;
  font-size: 16px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    display: inline-block;
    background: url(../imgs/paywall-description-star.png) no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    padding-right: 12px;
  }
  &-mob {
    @extend .paywall-description;
  }
}

.paywall-description-features-container {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  &-mob {
    @extend .paywall-description-features-container;
  }
}

.paywall-description-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  &-mob {
    @extend .paywall-description-feature;
  }
}

.paywall-description-feature-title {
  @include sf-semibold;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  &-mob {
    @extend .paywall-description-feature-title;
  }
}

.paywall-description-feature-subtitle {
  @include sf-regular;
  font-size: 14px;
  color: #BAC4C4;
 &-mob {
   @extend .paywall-description-feature-subtitle;
 }
}

.paywall-description-feature-line {
  width: 1px;
  height: 40px;
  background-color: #3B3F3F;
}

.paywall-step-container {
  display: flex;
  width: 24px;
  justify-content: space-between;
  margin-top: 12px;
  &-mob {
    @extend .paywall-step-container;
  }
}

.paywall-step {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #262828;
  &-active {
    @extend .paywall-step;
    background: #FFFFFF;
  }
}

.paywall-products-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-mob {
    @extend .paywall-products-container;
  }
}

.paywall-products-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 24px;
  margin-right: 24px;
  &-mob {
    @extend .paywall-products-container;
  }
}

.paywall-product {
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-mask-composite: destination-out;
  border-radius: 12px;
  border: 1px solid #333333;
  &-active {
    @extend .paywall-product;
    border: 2px solid var(--Main, #00F5EA);
    &-mob {
      @extend .paywall-product-active;
    }
  }
  &-mob {
    @extend .paywall-product;
  }
}

.product-circle {
  width: 20px;
  height: 20px;
  border: 2px solid #3B3F3F;
  border-radius: 30px;
  &-active {
    @extend .product-circle;
    width: 20px;
    height: 20px;
    border: 0px solid #3B3F3F;
    background: url("../imgs/product-select.png") no-repeat;
    background-size: contain;
  }
}

.paywall-product-body {
  display: flex;
  align-items: center;
  margin: 0px 24px;
  height: 73px;
}

.paywall-product-price-container {
  display: flex;
  margin-left: 16px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.paywall-product-price {
  @include sf-regular;
  display: flex;
  color: #808080;
  gap: 4px;
  .per-day {
    @include sf-medium;
    font-size: 8px;
    text-transform: uppercase;
  }
  &-active {
    @extend .paywall-product-price;
    color: #FFFFFF;
  }
}

.paywall-product-price-titles-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.paywall-product-price-title {
  @include sf-bold;
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #FFFFFF;
}

.paywall-product-price-subtitle {
  @include sf-regular;
  font-size: 14px;
  color: #808080;
}

.paywall-product-price-save {
  @include sf-bold;
  width: 100%;
  height: 18px;
  background: #333333;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  z-index: 4;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  &-active {
    @extend .paywall-product-price-save;
    background: #00f5ea;
    color: #000000;
  }
}

.paywall-guarantee-container {
  @include sf-regular;
  font-size: 14px;
  line-height: 17px;
  color: #BAC4C4;
  align-self: center;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  &::before {
    content: "";
    background: url("../imgs/paywall-guarantee.png") no-repeat;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    padding-right: 8px;
  }
}

.header-onboarding {
  position: relative;
  width: 100%;
  display: flex;
  &-mob {
    @extend .header-onboarding;
  }
}

.fltr-onboarding-logo-container {
  position: absolute;
  top: 16px;
  left: 16px;
  right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-mob {
    @extend .fltr-onboarding-logo-container;
    top: 16px;
  }
}

.fltr-onboarding-logo {
  width: 90px;
  height: 31px;
  &-mob {
    @extend .fltr-onboarding-logo;
    width: 90px;
    height: 31px;
  }
}

.paywall-body-title {
  @include sf-bold;
  max-width: 325px;
  align-self: center;
  color: white;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
}

.paywall-choose-plan-title {
  @include sf-regular;
  color: white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 16px;
}

.video-paywall {
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.pay-safe {
  margin-top: 16px;
  width: 100%;
  height: 92px;
}

.pay-safe-mob {
  height: 92px;
  width: 100%;
  margin-top: 18px;
}

.before-after-dots-container {
  padding-top: 16px;
  display: flex;
  gap: 8px;
}

.before-after-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #303333;
  &-active {
    @extend .before-after-dot;
    background-color: #00F5EA;
  }
}


